const TopHeader = () => {
  return (
    <div
      className={`font_normal bg-[rgba(16,80,121,0.6)] h-10 text-white flex justify-center items-center text-[13px]`}
    >
      <marquee direction="left">
        Kinaxis makes a new internal Unilever IT record by going live with 40+
        Markets on June 11th 2023 (One Day – 40 Markets)
        &nbsp;&nbsp;&nbsp;&nbsp;|| &nbsp;&nbsp;&nbsp;&nbsp; 7 Employee Twins
        LIVE across Unilever teams &nbsp;&nbsp;&nbsp;&nbsp;||
        &nbsp;&nbsp;&nbsp;&nbsp; Safety Suite (Video Analytics Product from HAP)
        now LIVE in 17 factories across South Asia &nbsp;&nbsp;&nbsp;&nbsp;||
        &nbsp;&nbsp;&nbsp;&nbsp; Safety Suite (Video Analytics Product from HAP)
        now LIVE in 17 factories across South Asia &nbsp;&nbsp;&nbsp;&nbsp; ||
        &nbsp;&nbsp;&nbsp;&nbsp; Share On Shelf (Image Analytics + AI/ML Product
        from HAP) now LIVE across South Asia & LATAM &nbsp;&nbsp;&nbsp;&nbsp;||
        &nbsp;&nbsp;&nbsp;&nbsp;NEO (Sales Order Creation RPA+ML Product from
        HAP) is now LIVE in Africa & South Asia) &nbsp;&nbsp;&nbsp;&nbsp; ||
        &nbsp;&nbsp;&nbsp;&nbsp; Invoice OCR (OCR Product from HAP) is now LIVE
        in SEAA, LATAM & Europe &nbsp;&nbsp;&nbsp;&nbsp;
        ||&nbsp;&nbsp;&nbsp;&nbsp; DFOS (Product from MAKE platform) now LIVE in
        Pondicherry, Igarassu, Covington & Haifa
        factories&nbsp;&nbsp;&nbsp;&nbsp; ||&nbsp;&nbsp;&nbsp;&nbsp; MCOF
        (Product from MAKE) now LIVE in Dukem Factory
      </marquee>
    </div>
  );
};

export default TopHeader;
