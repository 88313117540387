import Loader from "../shared/Loader";
import bg from "../../assets/images/illustration_min2.webp";
import loginImg from "../../assets/images/login_min.webp";
import loginImgBlur from "../../assets/images/login_blur.webp";
import unileverLogo from "../../assets/icons/unilever_logo.svg";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PacmanLoader } from "react-spinners";

const Login = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const {
    login,
    userState: { user, isLoading, isError, isSuccess },
    resetUserState,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (user && isSuccess) {
      navigate("/");
      resetUserState();
    }

    if (!user && isError) {
      toast.error("Login Error");
      resetUserState();
    }

    //eslint-disable-next-line
  }, [isError, isSuccess]);

  return (
    <div
      className="bg-contain h-[100vh]"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="w-full h-full flex justify-center items-center bg-[rgba(0,0,0,0.6)] relative">
        <div
          className={
            "w-full md:max-w-3xl md:mx-auto grid grid-cols-1 md:grid-cols-2"
          }
        >
          <>
            {isImageLoaded ? (
              <img
                src={loginImg}
                alt=""
                className="hidden md:block"
                onLoad={() => setIsImageLoaded(true)}
                loading="lazy"
              />
            ) : (
              <img
                src={loginImgBlur}
                style={{ filter: "blur(3px)" }}
                alt=""
                className="hidden md:block"
                onLoad={() => setIsImageLoaded(true)}
                loading="lazy"
              />
            )}
          </>
          <div className="bg-white flex flex-col justify-between">
            <div className="p-6 md:p-8 h-[60%] md:h-[70%]">
              <div className="flex items-center gap-4">
                <img alt="" src={unileverLogo} className="w-8" />
                <h1 className="font_bold text-[1.2rem]  text-[#1f36c7] w-[30%] leading-5 border-l-2 border-[#1f36c7] pl-4">
                  UnMaze OneTech
                </h1>
              </div>
              <h1 className="font_medium text-[#1f36c7] mt-4 md:mt-6">
                BUILDING THE SIMPLEST UNILEVER YET
              </h1>
              <p className="font_normal text-[rgb(115,112,110)] text-[12px] mt-2 md:mt-4">
                ‘UnMazeOneTech’ is a discovery portal for all the IT Products
                offered by the ‘OneTech’ organization.
              </p>
            </div>
            <div className="bg-[#1f36c7] font_normal h-[40%] md:h-[30%] relative py-2 md:py-0 text-white flex flex-col gap-4 justify-center items-center">
              <p className="text-center">Welcome to UnMaze OneTech</p>
              <button
                className="bg-white w-24 py-2 text-[#1f36c7] font_medium text-[14px]"
                onClick={login}
              >
                {isLoading ? <Loader /> : "Sign in"}
              </button>
            </div>
          </div>
        </div>
        {!isImageLoaded && (
          <div className="absolute w-full h-full justify-center items-center bg-white hidden md:flex">
            <PacmanLoader color="#1f36c7" />
          </div>
        )}
      </div>
    </div>
  );
};

// const Form = ({ email, setEmail, setShow }) => {
//   const {
//     login,
//     userState: { isLoading, isError, isSuccess, message, type },
//     verifyToken,
//     resetUserState,
//   } = useContext(AuthContext);

//   useEffect(() => {
//     if (isSuccess && type === "OTP Received") {
//       toast.success(message);
//       resetUserState();
//     }
//     if (isError && type === "OTP Received") {
//       console.log("login", message);
//       if (message === "jwt malformed") return;

//       toast.error(message);
//       resetUserState();
//     }

//     if (verifyToken) {
//       setShow({ emailForm: false, otpForm: true });
//     }

//     //eslint-disable-next-line
//   }, [isSuccess, isError]);

//   const onSubmit = async (e) => {
//     e.preventDefault();

//     if (!email) return;

//     if (!`${email}`.includes("@unilever.com"))
//       return toast.error("Need an Unilever email");

//     await login(email);
//   };

//   return (
//     <div className={"flex items-center h-screen w-full"}>
//       <div
//         className={
//           "w-full bg-white rounded-[0.8rem] shadow-lg p-8 m-4 md:max-w-sm md:mx-auto"
//         }
//       >
//         <span className="block w-full text-xl uppercase font-bold mb-4">
//           Login/Sign up
//         </span>
//         <form className="mb-4" onSubmit={onSubmit}>
//           <div className="mb-4 md:w-full">
//             <label htmlFor="email" className="block text-xs mb-1 font_normal">
//               Unilever Email
//             </label>
//             <input
//               className="w-full border rounded p-2 outline-none focus:shadow-outline"
//               type="email"
//               name="email"
//               id="email"
//               placeholder="Enter your email id"
//               maxLength={35}
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>
//           <button
//             type="submit"
//             className="bg-[#105079] hover:bg-[#4b9bcd] text-[#9DED5C] uppercase text-sm font-semibold px-4 py-2 rounded"
//           >
//             {isLoading ? <Loader /> : "Login"}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// Form.propTypes = {
//   email: PropTypes.string.isRequired,
//   setEmail: PropTypes.func,
//   setShow: PropTypes.func,
// };

// const OTPForm = () => {
//   const [timer, setTimer] = useState(30);
//   const [otp, setOtp] = useState({
//     digit1: "",
//     digit2: "",
//     digit3: "",
//     digit4: "",
//   });

//   const { digit1, digit2, digit3, digit4 } = otp;

//   const navigate = useNavigate();

//   const {
//     verifyOTP,
//     userState: { isSuccess, isError, message, type, isLoading },
//     resetUserState,
//     setTokenState,
//     resEmail,
//     login,
//   } = useContext(AuthContext);

//   useEffect(() => {
//     if (isSuccess && type === "OTP Verify") {
//       toast.success(message);
//       resetUserState();
//       setTokenState({
//         verifyToken: "",
//         tokenExpiredAt: "",
//       });
//       sessionStorage.removeItem("verifyToken");
//       navigate("/");
//     }

//     if (isError && type === "OTP Verify") {
//       let message1 = message === "jwt malformed" ? "OTP Expired" : message;
//       toast.error(message1);
//       resetUserState();
//     }

//     //eslint-disable-next-line
//   }, [isSuccess, isError]);

//   useEffect(() => {
//     if (isSuccess && type === "OTP Received") {
//       toast.success(message);
//       resetUserState();
//     }
//     if (isError && type === "OTP Received") {
//       console.log("message", message);
//       if (message === "jwt expired") return toast.error("Resend OTP");
//       toast.error(message);
//       resetUserState();
//     }
//     //eslint-disable-next-line
//   }, [isSuccess, isError]);

//   useEffect(() => {
//     if (timer > 0) {
//       setTimeout(() => setTimer(timer - 1), 1000);
//     }
//   }, [timer]);

//   const handleOTP = (e) => {
//     if (e.target.value < 10) {
//       setOtp({ ...otp, [e.target.name]: e.target.value });
//     }
//   };

//   const inputfocus = (elmnt) => {
//     if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
//       const next = elmnt.target.tabIndex - 2;
//       if (next > -1) {
//         elmnt.target.form.elements[next].focus();
//       }
//     } else {
//       const next = elmnt.target.tabIndex;
//       if (next < 4) {
//         elmnt.target.form.elements[next].focus();
//       }
//     }
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     if (!digit1 || !digit2 || !digit3 || !digit4) {
//       return toast.error("Check OTP field");
//     }

//     const otp = Number(digit1 + digit2 + digit3 + digit4);

//     await verifyOTP(otp);
//   };

//   const resendOTP = async () => {
//     if (timer < 1) {
//       setOtp({
//         digit1: "",
//         digit2: "",
//         digit3: "",
//         digit4: "",
//       });
//       await login(resEmail);
//     }
//   };

//   const inputs =
//     "border-[1px] border-[rgba(0,0,0,0.1)] outline-none aspect-square w-10 p-[0.5rem] text-center focus:border-[rgba(0,0,0,0.35)] transition-all";
//   return (
//     <div className={"flex items-center h-screen w-full"}>
//       <div
//         className={
//           "w-full bg-white rounded-[0.8rem] shadow-lg p-8 m-4 md:max-w-sm md:mx-auto"
//         }
//       >
//         <span className="block w-full text-xl uppercase font-bold text-center">
//           Enter OTP
//         </span>
//         <span className="text-[0.8rem] text-center block w-full">
//           sent to {resEmail}
//         </span>
//         <form className="mb-4 mt-4" onSubmit={onSubmit}>
//           <div className="mb-4 md:w-full flex justify-center items-center gap-3">
//             <input
//               type="number"
//               name="digit1"
//               id=""
//               className={inputs}
//               value={digit1}
//               onChange={handleOTP}
//               min={0}
//               max={9}
//               maxLength={"1"}
//               tabIndex={1}
//               onKeyUp={inputfocus}
//             />
//             <input
//               type="number"
//               name="digit2"
//               id=""
//               className={inputs}
//               value={digit2}
//               onChange={handleOTP}
//               min={0}
//               max={9}
//               maxLength={"1"}
//               tabIndex={2}
//               onKeyUp={inputfocus}
//             />
//             <input
//               type="number"
//               name="digit3"
//               id=""
//               className={inputs}
//               value={digit3}
//               onChange={handleOTP}
//               min={0}
//               max={9}
//               maxLength={"1"}
//               tabIndex={3}
//               onKeyUp={inputfocus}
//             />
//             <input
//               type="number"
//               name="digit4"
//               id=""
//               className={inputs}
//               value={digit4}
//               onChange={handleOTP}
//               min={0}
//               max={9}
//               maxLength={1}
//               tabIndex={4}
//               onKeyUp={inputfocus}
//             />
//           </div>
//           <button
//             type="submit"
//             className="bg-[#105079] hover:bg-[#4b9bcd] text-[#9DED5C] uppercase text-sm font-semibold px-4 py-2 rounded"
//           >
//             {isLoading ? <Loader /> : "Submit"}
//           </button>
//           <div className="text-center text-[0.9rem] mt-4">
//             <span onClick={resendOTP} className="cursor-pointer">
//               Resend OTP
//             </span>
//             <span className="ml-2 font_medium">
//               <span>00</span> :
//               <span>
//                 {" "}
//                 {timer < 10 && 0}
//                 {timer}
//               </span>
//             </span>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

export default Login;
