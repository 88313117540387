import PropTypes from "prop-types";
import arrow from "../../assets/icons/arrow.svg";
import { useEffect, useState } from "react";

const StatItem = ({ stat }) => {
  const [screenWidth, setScreenWith] = useState("");

  useEffect(() => {
    setScreenWith(window.outerWidth);
  }, []);

  window.addEventListener("resize", () => setScreenWith(window.outerWidth));

  const statStyle =
    screenWidth > 768
      ? {
          marginTop: stat?.id % 2 === 0 ? "4rem" : 0,
          height: stat?.id % 2 !== 0 ? "82%" : "inherit",
        }
      : {};

  return (
    <div
      className="border-l-[2px] border-t-[2px] border-[#9DED5C] rounded-[1rem] pl-8 pt-4 w-[80%] lg:w-[85%]"
      style={statStyle}
    >
      <h1 className="text-[3.5rem] font_medium">{stat?.value}</h1>
      <h3 className="text-[1rem] font_medium w-full">{stat?.title}</h3>
      <img src={arrow} alt="arrow" className="w-8 h-8 aspect-square mt-6" />
    </div>
  );
};

export default StatItem;

StatItem.propTypes = {
  stat: PropTypes.object,
};
