import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ProductProvider } from "./context/ProductContext";
import { AuthProvider } from "./context/AuthContext";
import RouterComponent from "./Router";

function App() {
  return (
    <AuthProvider>
      <ProductProvider>
        <Router>
          <RouterComponent />
        </Router>
      </ProductProvider>
    </AuthProvider>
  );
}

export default App;
