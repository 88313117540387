import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userState, setUserState] = useState({
    user: JSON.parse(sessionStorage.getItem("user")) || null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  });

  const msalInstance = new PublicClientApplication(msalConfig);
  let isMsalInitialized = false;

  const setToLoading = () => {
    setUserState({ ...userState, isLoading: true });
  };

  const resetUserState = () =>
    setUserState({
      ...userState,
      isLoading: false,
      isError: false,
      isSuccess: false,
      message: "",
    });

  const initializeMsal = async () => {
    try {
      await msalInstance.initialize();
      isMsalInitialized = true;
    } catch (error) {
      console.error("MSAL initialization error:", error);
    }
  };

  const login = async () => {
    setToLoading();
    try {
      if (!isMsalInitialized) {
        await initializeMsal();
      }
      const loginResponse = await msalInstance.loginPopup();
      setUserState({
        user: {
          name: loginResponse?.account?.name,
          token: loginResponse?.idToken,
          email: loginResponse?.account?.username,
        },
        isLoading: false,
        isError: false,
        isSuccess: true,
        message: "Login Sucess",
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          name: loginResponse?.account?.name,
          token: loginResponse?.idToken,
          email: loginResponse?.account?.username,
        })
      );
    } catch (error) {
      setUserState({ ...userState, isError: true });
      console.error("Login error:", error);
    }
  };

  initializeMsal();

  const logout = () => {
    setUserState({ ...userState, user: null });
    sessionStorage.removeItem("user");
  };

  useEffect(() => {
    const checkAccount = async () => {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        //console.log("accounts", accounts);
      }
    };

    checkAccount();

    //eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ userState, logout, login, resetUserState }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
