import cloud from "../../assets/icons/cloud.svg";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import style from "./productitem.module.css";
import { useContext, useState } from "react";
import ProductContext from "../../context/ProductContext";

const ProductItem = ({ product }) => {
  const [clicked, setClicked] = useState(false);
  const { productState } = useContext(ProductContext);
  const navigate = useNavigate();

  return (
    <div
      className={`${style.card} ${clicked ? style.active : ""}`}
      onClick={() => setClicked(!clicked)}
    >
      <div className={style.front}>
        <div className="bg-[#105079] text-white flex items-center gap-3 rounded-[16px] pt-4 pb-[2rem] px-8">
          <img
            src={cloud}
            alt="server cloud"
            className="w-10 h-10"
            loading="lazy"
          />
          <div className="font_medium text-[12px]">
            <h2>{product?.Function}</h2>
            <h1 className=" lg:text-[14px] xl:text-[10px]">
              {`${product["Product name"]}`}
            </h1>
          </div>
        </div>
        <div className="relative w-[86%] bg-[#EBF3FA] rounded-t-[16px] rounded-b-[6px] top-[-1.25rem] left-[50%] translate-x-[-50%] text-[14px] font_normal py-4 shadow-md">
          <div className="text-[11px] px-[1.3rem] pb-[8px]">
            {`${
              product?.["Product description"]?.split(".")[0] || "Not available"
            }`}
            .
          </div>
          <div className="flex border-t-[1px] border-b-[1px] border-[rgba(90,90,90,0.3)] text-[11px] font_medium">
            <div className="px-[1.3rem] w-[70%] border-r-[1px] border-[rgba(90,90,90,0.3)] py-2 flex flex-col gap-[0rem]">
              <h2 className="text-[#005EEF]">Area</h2>
              <h2>{product?.Area}</h2>
            </div>
            <div className="px-[1.3rem] py-2 flex flex-col gap-[0rem]">
              <h1 className="text-[#005EEF]">Cluster</h1>
              <h2>{product?.ClusterLive}</h2>
            </div>
          </div>
          <div className="absolute text-center top-[4.25rem] rotate-[90deg] bg-[#00AF30] text-white text-[8px] rounded-t-[3px] right-[-18px] w-[28px]">
            {product?.Status}
          </div>
        </div>
      </div>
      <div className={style.back}>
        <h1 className="font_medium text-center mt-6 text-[#105079]">
          {product?.Function}
        </h1>
        <div className="text-center py-4 text-[#105079]">
          <span className="font_medium text-[14px]">IT Owner</span> :{" "}
          <span>
            {product?.Area === "Make"
              ? "bhaskar.das@unilever.com"
              : product?.Area === "Plan"
              ? "ayush.kumar@unilever.com"
              : product?.Area === "Hyper Automation"
              ? "vivek.sharma@unilever.com"
              : "vivek.sharma@unilever.com"}
          </span>
        </div>
        <div className="uppercase font_medium text-[0.6rem] flex justify-between items-center px-6 mt-2">
          <span
            onClick={() =>
              navigate(`/product/${product._id}`, {
                state: { product, products: productState.products },
              })
            }
            className="cursor-pointer whitespace-nowrap text-[#005EEF]"
          >
            More details
          </span>
          <a
            target="_blank"
            rel="noreferrer"
            href={product?.["Documents link"]}
            className="text-[#9DED5C] text-center outline-none border-none bg-[#105079] px-2 py-1 rounded-[4px]"
          >
            DOWNLOAD
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;

ProductItem.propTypes = {
  product: PropTypes.object,
};
