import { useLocation } from "react-router-dom";
import document from "../../assets/images/document.png";
import PropTypes from "prop-types";
const ProductDetails = ({
  product,
  changeProduct,
  setProduct,
  setCurrentIndex,
  currentIndex,
}) => {
  if (!product) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div className="mt-10 flex justify-between px-4 lg:px-[3rem]">
      <AboutProduct
        product={product}
        changeProduct={changeProduct}
        setProduct={setProduct}
        setCurrentIndex={setCurrentIndex}
        currentIndex={currentIndex}
      />
      <ProductDocument />
    </div>
  );
};

const AboutProduct = ({ product, setCurrentIndex, currentIndex }) => {
  const location = useLocation();

  // console.log(location.state.products.length, currentIndex);

  const listgroup = "flex flex-col lg:flex-row gap-8 lg:gap-0";
  const listtitle = "bg-[#8EC3E5] inline px-4 lg:px-6 py-2 rounded-[0.7rem]";
  const listdesc = "text-[#1F36C7] text-[0.85rem] mt-4 ml-3";

  return (
    <div className="bg-[#EBF3FA] flex relative px-4 lg:px-[3rem] py-[3.5rem] rounded-[1.5rem]  flex-col gap-10 font_medium w-full lg:w-[55%]">
      <div className={listgroup}>
        <div className="">
          <div className={`${listtitle}`}>Process</div>
          <h1 className={`${listdesc}`}>{product?.Area}</h1>
        </div>
        <div className="">
          <div className={`${listtitle}`}>Product Name</div>
          <h1 className={`${listdesc}`}>{product?.["Product name"]}</h1>
        </div>
      </div>
      <div>
        <div className={`${listtitle}`}>Description</div>
        <h1 className={`${listdesc}`}>{product?.["Product description"]}</h1>
      </div>
      <div className={listgroup}>
        <div>
          <div className={`${listtitle}`}>Status</div>
          <h1 className={`${listdesc}`}>{product?.Status}</h1>
        </div>

        {product?.Area && (
          <div>
            <div className={`${listtitle}`}>IT Owner</div>
            <h1 className={`${listdesc}`}>
              {product?.Area === "Make" ? (
                <a href="mailto:bhaskar.das@unilever.com">
                  bhaskar.das@unilever.com
                </a>
              ) : product?.Area === "Plan" ? (
                <a href="mailto:ayush.kumar@unilever.com">
                  ayush.kumar@unilever.com
                </a>
              ) : product?.Area === "Hyper Automation" ? (
                <a href="mailto:vivek.sharma@unilever.com">
                  vivek.sharma@unilever.com
                </a>
              ) : (
                ""
              )}
            </h1>
          </div>
        )}
      </div>
      <div className={listgroup}>
        <div>
          <div className={`${listtitle}`}>Number of users</div>
          <h1 className={`${listdesc}`}>
            {product?.["Number of users"] || "Not available"}
          </h1>
        </div>
        <div>
          <div className={`${listtitle}`}>Cluster</div>
          <h1 className={`${listdesc}`}>{product?.["ClusterLive"]}</h1>
        </div>
      </div>
      <div className={listgroup}>
        {product?.["Documents link"] && (
          <div>
            <div className={`${listtitle}`}>Document Link</div>
            <a
              href={product?.["Documents link"]}
              target="_blank"
              rel="noreferrer"
              className={`${listdesc} block`}
            >
              Click here
            </a>
          </div>
        )}
        {product?.["Video link"] && (
          <div>
            <div className={`${listtitle}`}>Video Link</div>
            <a
              href={product?.["Video link"]}
              target="_blank"
              rel="noreferrer"
              className={`${listdesc} block`}
            >
              Click here
            </a>
          </div>
        )}
      </div>
      <button
        className="active:scale-[0.96] transition-all disabled:pointer-events-none disabled:bg-gray-400 absolute left-0 top-[-2rem] bg-[#105079] text-[#fff] w-[20%] lg:w-[10%] text-[13px] py-1 rounded-l-xl"
        onClick={() => setCurrentIndex((prev) => prev - 1)}
        disabled={currentIndex < 0}
      >
        Prev
      </button>
      <button
        className="active:scale-[0.96] disabled:pointer-events-none disabled:bg-gray-400 transition-all absolute right-1 top-[-2rem] bg-[#105079] text-[#fff] w-[20%] lg:w-[10%] py-1 text-[13px] rounded-r-xl"
        onClick={() => setCurrentIndex((prev) => prev + 1)}
        disabled={currentIndex > location.state?.products?.length - 1}
      >
        Next
      </button>
    </div>
  );
};

AboutProduct.propTypes = {
  product: PropTypes.object,
  changeProduct: PropTypes.object,
  setProduct: PropTypes.func,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
};
ProductDetails.propTypes = {
  product: PropTypes.object,
  changeProduct: PropTypes.object,
  setProduct: PropTypes.func,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
};

const ProductDocument = () => {
  return (
    <div className="w-[41%] lg:flex flex-col gap-4 hidden">
      <img src={document} loading="lazy" className="w-full" alt="" />
    </div>
  );
};

export default ProductDetails;
