import { useState } from "react";
import { clusterData } from "../../data/cluster.data";
import { filterData } from "../../data/filter.data";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import ProductContext from "../../context/ProductContext";
import { useEffect } from "react";
import searchIcon from "../../assets/icons/yellow_search.svg";
import Loader from "../shared/Loader";

const Cluster = () => {
  const [filter, setFilter] = useState(
    JSON.parse(sessionStorage.getItem("filter")) || {
      selectedCluster: "Cluster",
      selectedFunc: "Functions",
      selectedProcess: "Process",
      selectedSubProcess: "Sub-Process",
    }
  );

  const [process, setProcess] = useState([]);
  const [subProcess, setSubProcess] = useState([]);

  const { selectedCluster, selectedFunc, selectedProcess, selectedSubProcess } =
    filter;

  const {
    getFilterProducts,
    productState: { isLoading },
  } = useContext(ProductContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      selectedFunc === "Supply Chain" &&
      selectedProcess !== "Process" &&
      process?.length !== 0
    ) {
      const subProcessData =
        filterData["Supply Chain"]["Sub area"][selectedProcess];
      console.log("subprocess", subProcessData);
      setSubProcess(subProcessData);
    }

    //eslint-disable-next-line
  }, [selectedProcess, process]);

  useEffect(() => {
    if (selectedFunc !== "Functions") {
      const functionDetails = filterData[selectedFunc];
      setProcess(functionDetails["Area"]);
      if (selectedFunc !== "Supply Chain") {
        setSubProcess(functionDetails["Sub area"]);
      } else {
        setSubProcess([]);
      }
    } else {
      setProcess([]);
      setSubProcess([]);
    }
  }, [selectedFunc]);

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const onSearch = async (e) => {
    e.preventDefault();

    if (
      selectedCluster === "Cluster" &&
      selectedFunc === "Functions" &&
      selectedProcess === "Process" &&
      selectedSubProcess === "Sub-Process"
    )
      return toast.info("Choose at least one filter");

    const querySend = {
      selectedCluster,
      selectedFunc,
      selectedProcess,
      selectedSubProcess,
    };

    const query = {
      limit: 12,
      cluster: selectedCluster === "Cluster" ? "Global" : selectedCluster,
      function: selectedFunc === "Functions" ? "" : selectedFunc,
      area: selectedProcess === "Process" ? "" : selectedProcess,
      subarea: selectedSubProcess === "Sub-Process" ? "" : selectedSubProcess,
    };

    await getFilterProducts(query);

    sessionStorage.setItem("filter", JSON.stringify(querySend));

    navigate("/search");
  };

  const selectstyle =
    "w-[80%] lg:w-[22%] h-[3rem] 2xl:h-[4rem] px-5 py-2 text-[1.2rem] text-[1rem] lg:text-[1.4rem] font_medium text-[#005EEF] mx-4 border-none outline-none rounded-[4px]";
  const groupstyle =
    "text-[#105079] mt-2 lg:mt-6 2xl:mt-[2rem] flex flex-col lg:flex-row justify-center gap-3 lg:gap-0 items-center";
  const optionStyle = "text-[0.85rem] lg:text-[1rem]";
  return (
    <form
      className="font_bold text-[1.3rem] lg:text-[2rem] 2xl:text-[2.3rem] mt-4 lg:mt-6"
      onSubmit={onSearch}
    >
      <div className="text-center text-[#105079]">
        I want to discover products /
      </div>
      <div className={`${groupstyle} mt-[-0.4rem] lg:mt-6`}>
        capabilities for{" "}
        <select
          className={selectstyle}
          value={selectedCluster}
          name="selectedCluster"
          onChange={handleFilterChange}
        >
          <option value={"Cluster"} className={optionStyle} disabled>
            Cluster
          </option>
          {clusterData.map((cluster) => (
            <option className={optionStyle} key={uuidv4()} value={cluster}>
              {cluster}
            </option>
          ))}
        </select>{" "}
      </div>
      <div className={`${groupstyle}`}>
        <div className="w-[32px]  border-black hidden lg:block">in</div>
        <select
          className={selectstyle}
          value={selectedFunc}
          name="selectedFunc"
          onChange={handleFilterChange}
        >
          <option className={optionStyle} value={"Functions"} disabled>
            Functions
          </option>
          {Object.keys(filterData).map((func) => (
            <option className={optionStyle} value={func} key={uuidv4()}>
              {func}
            </option>
          ))}
        </select>{" "}
        <div className=" border-black w-[10px] hidden lg:block">,</div>
        <select
          className={selectstyle}
          value={selectedProcess}
          name="selectedProcess"
          onChange={handleFilterChange}
        >
          <option className={optionStyle} value={"Process"} disabled>
            Process
          </option>
          {process.map((prc) => (
            <option className={optionStyle} value={prc} key={uuidv4()}>
              {prc}
            </option>
          ))}
        </select>
        <div className=" border-black w-[10px] hidden lg:block">,</div>
        <select
          className={selectstyle}
          value={selectedSubProcess}
          name="selectedSubProcess"
          onChange={handleFilterChange}
        >
          <option className={optionStyle} value={"Sub-Process"} disabled>
            Sub-Process
          </option>
          {subProcess.map((sub) => (
            <option className={optionStyle} key={uuidv4()} value={sub}>
              {sub}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-center items-center gap-4 2xl:mt-3">
        <button
          type="submit"
          className="bg-[#1F36C7] text-[#9DED5C] flex justify-center items-center mt-4 lg:mt-6 uppercase text-[1rem] gap-1 lg:gap-0 lg:text-[1.6rem] w-[8rem] lg:w-[12rem] py-[0.35rem] lg:py-2 rounded-[4px]"
        >
          {isLoading ? (
            <Loader color={"#fff"} style={{ marginLeft: "1rem" }} />
          ) : (
            <>
              <img
                src={searchIcon}
                className="w-4 lg:w-8 ml-5 lg:ml-5 mt-1"
                alt=""
              />{" "}
              <span className="pr-4 lg:pr-2">UnMaze</span>
            </>
          )}
        </button>

        {sessionStorage.getItem("filter") && (
          <button
            type="button"
            onClick={() => {
              sessionStorage.removeItem("filter");
              setFilter({
                selectedCluster: "Cluster",
                selectedFunc: "Functions",
                selectedProcess: "Process",
                selectedSubProcess: "Sub-Process",
              });
            }}
            className="bg-[#105079] text-[#9DED5C] mt-4 lg:mt-6  uppercase text-[1rem] lg:text-[1.6rem] w-[8rem] lg:w-[12rem] py-[0.35rem] lg:py-2 rounded-[4px]"
          >
            Reset
          </button>
        )}
      </div>
    </form>
  );
};

export default Cluster;
