import cross from "../../assets/icons/cross2.svg";
import copy from "../../assets/icons/copy.svg";
import { TbLogout } from "react-icons/tb";
import { useContext, useRef } from "react";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";
import ExcelDownloadButton from "../../utils/ExcelDownload";

const Sidebar = () => {
  const {
    userState: { user },
    logout,
  } = useContext(AuthContext);

  const textRef = useRef(null);

  const copyText = () => {
    const textToCopy = textRef.current.innerText;

    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Text copied to clipboard: " + textToCopy);
        })
        .catch((error) => {
          console.error("Copy failed:", error);
        });
    }
  };

  return (
    <div className="drawer-side z-40">
      <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
      <div className="bg-[#105079] w-full h-full px-6">
        <label
          htmlFor="my-drawer-4"
          className="w-8 p-2 rounded-full absolute right-6 top-6 bg-[rgba(255,255,255,0.1)]"
        >
          <img alt="" src={cross} />
        </label>
        <div className="mt-[5rem] bg-[rgba(255,255,255,0.1)] flex flex-col py-6 gap-4 items-center rounded-lg">
          <h1 className="text-center font_bold text-white text-[1.2rem]">
            Upload My Products
          </h1>
          <div className="text-center font_bold text-white text-[14px] ">
            Please download the template,
            <br /> fill and mail it to
            <span className="ml-1 text-[#8EC3E5]" ref={textRef}>
              hap@uniliver.com
            </span>
            <img
              src={copy}
              alt=""
              className="w-5 inline ml-1"
              onClick={copyText}
            />
          </div>
          <ExcelDownloadButton font="15px" bg="#105079" text="#9DED5C" />
        </div>
        <div className="mt-8 rounded-lg font_medium text-white bg-[rgba(255,255,255,0.1)] flex flex-col justify-between">
          <div className="flex gap-4 p-5">
            <div className="w-[30%] aspect-square rounded-full flex justify-center items-center uppercase text-[34px] bg-[#8EC3E5]">
              {`${user?.name?.split(" ")[0][0]}` +
                `${user?.name?.split(" ")[1][0]}`}
            </div>
            <div className="w-[70%] flex flex-col gap-1 justify-center">
              <div className="text-[17px] capitalize">{user?.name}</div>
              <div className="text-[12px] lowercase"> {user?.email}</div>
            </div>
          </div>
          <div
            className="rounded-b-lg flex justify-center items-center bg-[#FF6565] py-3"
            onClick={logout}
          >
            <TbLogout className="w-6" />
            <span className="w-8 text-[12px]">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
