import OnetechLogo from "../shared/OnetechLogo";
import TopHeader from "../shared/TopHeader";
import User from "../shared/User";
import PropTypes from "prop-types";
import bg from "../../assets/images/product_page_background.png";
import HamBurger from "./HamBurger";

const Navbar = ({ product }) => {
  if (!product) {
    return <h1>Something went wrong</h1>;
  }

  return (
    <div>
      <TopHeader />
      <nav className="bg-[#105079] h-[4.5rem] lg:h-[5.4rem] flex items-center justify-between px-4 lg:px-12 w-full">
        <OnetechLogo />
        <User />
        <HamBurger />
      </nav>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="flex items-center bg-cover border-[1px] pl-12 lg:bg-contain 2xl:bg-cover h-[20vh] lg:h-[21.3rem] bg-no-repeat leading-9"
      >
        <div>
          <h1 className="w-[90%] ml-[-1.5rem] leading-6 lg:leading-8 lg:ml-0  font_medium text-[#005EEF] text-[1.2rem] lg:text-[1.8rem] mt-0 lg:mt-32">
            {product?.ClusterLive}/{product?.Function}/{product?.Area}/
            {product?.["Sub area"]}
          </h1>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  product: PropTypes.object,
};

export default Navbar;
