import logo from "../../assets/icons/logo.svg";
import { Link } from "react-router-dom";

const OnetechLogo = () => {
  return (
    <Link
      to={"/"}
      className="flex items-center text-white font_bold text-[15px] md:text-[18px] w-[45%]"
    >
      <img
        src={logo}
        alt="Site Logo"
        className="w-8 h-8 lg:w-10"
        loading="lazy"
      />
      <div className="leading-[1.4rem]">
        <div>UnMaze</div>
        <div>OneTech</div>
      </div>
    </Link>
  );
};

export default OnetechLogo;
