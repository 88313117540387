import { useContext } from "react";
import ProductList from "../products/ProductList";
import Filter from "./Filter";
import ProductContext from "../../context/ProductContext";

const FilterSearchResult = () => {
  const {
    productState: { products, totalDataCount },
  } = useContext(ProductContext);

  return (
    <div className="">
      <h1 className="text-[#868686] font_medium px-4 lg:px-[4rem]">
        showing “<span className="text-[#1F36C7]">{products.length}</span>”
        Results{" "}
        {totalDataCount > 0 && (
          <span>
            of <span className="text-[#1F36C7]">{totalDataCount}</span> products
          </span>
        )}
      </h1>

      <Filter />
      <ProductList />
    </div>
  );
};

export default FilterSearchResult;
