import StatItem from "./StatItem";
import statData from "./stat.data";
import { v4 as uuidv4 } from "uuid";
// import Ellipse25 from "../../assets/images/Ellipse25.png";
// import Image5 from "../../assets/images/image5.png";
//import { useContext } from "react";
//import ProductContext from "../../context/ProductContext";

const About = () => {
  //const { setIsPlaying } = useContext(ProductContext);
  return (
    <>
      <div className="bg-[#105079] font_normal text-white flex flex-col md:flex-row px-6 lg:px-[4.5rem] py-6 lg:py-24 mt-8 lg:mt-[4.5rem] rounded-[1rem] xl:rounded-[4rem] w-[95%] mx-auto">
        <div className="w-full md:w-[50%] ">
          <h1 className="text-[2rem] md:text-[2.6rem] leading-9 text-center lg:text-left lg:leading-[2.8rem] font_bold">
            About <br />
            <span className="text-[#9DED5C]">UnMaze Onetech</span>
          </h1>
          <div className=" w-full lg:w-[80%]  mt-6 text-center lg:text-left  lg:mt-12  text-[1rem] lg:text-[1.15rem] leading-[1.4rem] flex flex-col gap-4">
            <p>
              ‘UnMazeOneTech’ is a repository/catalogue of all Products offered
              by the ‘OneTech’ organization. Currently, Products offered for
              ‘Supply Chain – Plan’, ‘Supply Chain – Make’ and ‘HyperAutomation
              Platform’ are listed. Shortly, products from all of the ‘OneTech’
              platforms would be listed.
            </p>
            <p>
              ‘UnMazeOneTech’ intends to simplify discovery of IT Products from
              the universe of existing offerings from OneTech for Business
              stakeholders and UniOps-In-Market functions.
            </p>

            <p>
              If you are from any of the ‘OneTech’ platforms and want to get
              your Products listed here, please use the ‘Upload my Products’
              button on the top Navigation bar.
            </p>
            <p>
              If you are from Business or UniOps-In-Market, happy UnMazing! 😊
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 mt-8  lg:mt-0 lg:grid-cols-2 w-full lg:w-[50%]  gap-8 place-items-center lg:place-items-start lg:gap-2">
          {statData.map((stat) => (
            <StatItem key={uuidv4()} stat={stat} />
          ))}
        </div>
      </div>
      {/* <div className="flex justify-center items-center relative">
        <div className="absolute -top-[9rem] left-[50%] translate-x-[-50%] w-[31%]">
          <img
            src={Ellipse25}
            alt="ellipse"
            className="w-[25.5rem] h-[16.5rem]"
          />
          <label htmlFor="video_player" onClick={() => setIsPlaying(true)}>
            <img
              src={Image5}
              alt="image5"
              className="cursor-pointer absolute top-[60.8%] left-[51.6%] 2xl:left-[46%] translate-x-[-50%] translate-y-[-50%] w-[10rem] aspect-square"
            />
          </label>
        </div>
      </div> */}
    </>
  );
};

export default About;
