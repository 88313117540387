import ProductItem from "./ProductItem";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useContext, useRef } from "react";
import ProductContext from "../../context/ProductContext";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Skeleton from "../shared/Skeleton";

const ProductList = () => {
  const {
    getPopularProducts,
    productState: {
      products,
      isLoading,
      isSuccess,
      isError,
      type,
      message,
      totalDataCount,
    },
    resetProductState,
    getFilterProducts,
  } = useContext(ProductContext);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams({ page: 1 });

  const location = useLocation();
  const ref = useRef(true);
  const ref2 = useRef(true);

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }
    if (ref2.current) {
      ref2.current = false;
      return;
    }

    if (location.pathname === "/search") {
      let query = JSON.parse(sessionStorage.getItem("query"));
      query = { ...query, skip: params.get("page") };
      console.log("params query calling", query);
      getFilterProducts(query);
    }

    //eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (isSuccess && type === "products/get") {
      resetProductState();
    }

    if (isError && type === "products/get") {
      toast.error(message);
      resetProductState();
    }

    //eslint-disable-next-line
  }, [isError, isSuccess]);

  useEffect(() => {
    if (location.pathname === "/") {
      getPopularProducts();
    }
    //eslint-disable-next-line
  }, [location.pathname]);

  const onFindMoreProducts = () => {
    navigate("/search");
  };

  const onLoadMoreProducts = () => {
    setParams({ page: Number(params.get("page")) + 1 });
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (!products.length)
    return (
      <h1 className="px-14 text-[3rem] text-[#868686]">No Product Found</h1>
    );

  return (
    <div className="">
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-10 px-8 lg:px-16">
        {products.map((product) => (
          <ProductItem key={uuidv4()} product={product} />
        ))}
      </div>
      <div className="flex justify-center items-center mt-8">
        {location.pathname === "/" ? (
          <button
            onClick={onFindMoreProducts}
            className="bg-[#105079] px-4 py-3 outline-none text-[#9DED5C] w-[50%] lg:w-[17%] text-[14px]  rounded-[6px] font_medium uppercase"
          >
            Load More
          </button>
        ) : (
          <button
            disabled={products.length === totalDataCount}
            onClick={onLoadMoreProducts}
            className="bg-[#105079] px-4 py-3 outline-none text-[#9DED5C] w-[50%] lg:w-[17%] text-[14px]  rounded-[6px] font_medium uppercase disabled:bg-gray-400"
          >
            Load more
          </button>
        )}
      </div>
    </div>
  );
};

ProductList.propTypes = {
  currentProductStatus: PropTypes.string,
};

export default ProductList;
