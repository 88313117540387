import { useState } from "react";
import search from "../../assets/icons/search_blue.svg";
import { useContext } from "react";
import ProductContext from "../../context/ProductContext";

const SearchComponent = () => {
  const [searchText, setSearchText] = useState("");
  const { getProducts } = useContext(ProductContext);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!searchText) return;

    if (searchText.length > 2) {
      const query = { search: searchText, limit: 12 };
      await getProducts(query);
    }
    //setToSearchWithText();
  };

  return (
    <div className="flex justify-center items-center relative">
      <form
        onSubmit={onSubmit}
        className="flex items-center w-[90%] sm:w-[80%] lg:w-[35%] px-5 py-3 rounded-[4px] shadow-md relative top-[-1.9rem] z-10 bg-white"
      >
        <img src={search} alt="search" className="w-4 aspect-square" />
        <input
          type="search"
          name=""
          id=""
          className="w-[78%] border-none outline-none ml-2 font_normal text-[0.9rem] mr-1"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          maxLength={40}
        />
        <button
          type="submit"
          className="bg-[#1f36c7] text-white text-[14px] lg:text-[16px] w-[35%] lg:w-[25%] pb-1 rounded-[5px] font_medium"
        >
          Unmaze
        </button>
      </form>
    </div>
  );
};

export default SearchComponent;
