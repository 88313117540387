import About from "../components/about/About";
import Navbar from "../components/navbar/Navbar.home";

import Sidebar from "../components/navbar/Sidebar";
import Product from "../components/products/Product";

//import Testimonials from "../components/testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <div className="drawer drawer-end">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <Navbar />
          <Product />
          <About />
        </div>
        <Sidebar />
      </div>
    </>
  );
};

export default Home;
