import ham from "../../assets/icons/ham.svg";

const HamBurger = () => {
  return (
    <>
      <label htmlFor="my-drawer-4" className="w-[1.5rem] md:hidden">
        <img src={ham} loading="lazy" alt="" className="cursor-pointer" />
      </label>
    </>
  );
};

export default HamBurger;
