import { useContext, useState } from "react";
import userIcon from "../../assets/icons/user.svg";
import AuthContext from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";

const User = () => {
  const [showDetails, setShowDetails] = useState(false);
  const {
    userState: { user },
    logout,
  } = useContext(AuthContext);

  const location = useLocation();

  return (
    <div
      className={`font_bold text-white  hidden lg:flex justify-end ${
        location.pathname === "/" ? "w-[80%]" : "w-[23%]"
      }`}
    >
      <div className="lg:w-[50%] xl:w-[70%]  justify-end flex gap-2 items-center mr-4">
        <div className="text-center whitespace-nowrap">
          HELLO!{" "}
          <span className="text-[#9DED5C] uppercase ml-1">
            {user?.name?.split(" ")[1]}
          </span>
        </div>
        <div className="relative w-[6rem] ">
          <img
            onMouseOver={() => setShowDetails(true)}
            onMouseOut={() => setShowDetails(false)}
            src={userIcon}
            alt="user"
            className="w-full h-full cursor-pointer z-30"
            loading="lazy"
          />
          {showDetails && (
            <div
              className=" h-[8rem] pt-[2rem] w-[15rem] right-0 top-4 absolute"
              onMouseOver={() => setShowDetails(true)}
              onMouseOut={() => setShowDetails(false)}
            >
              <div className="absolute w-[15rem]  bg-[#fff] rounded-[1rem]">
                <div className="flex items-center gap-2 rounded-[1rem] px-4 py-3">
                  <div className="bg-[#9DED5C] uppercase text-[#105079] text-[1.7rem] aspect-square w-[30%] rounded-full flex justify-center items-center">
                    {`${user?.name?.split(" ")[0][0]}` +
                      `${user?.name?.split(" ")[1][0]}`}
                  </div>
                  <div className="font_normal text-[13px] text-[#000] w-[70%]">
                    <p className="font_medium capitalize">{user?.name}</p>
                    <p className="text-[9px] text-[#005EEF] font_medium">
                      {user?.email}
                    </p>
                  </div>
                </div>
                <div
                  onClick={logout}
                  className=" cursor-pointer bg-[#105079] rounded-b-[1rem] flex justify-center font_normal items-center py-1"
                >
                  <FiLogOut className="w-[10%]" />{" "}
                  <span className="text-[11px] w-[20%]">Logout</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default User;
