import OnetechLogo from "../shared/OnetechLogo";
import TopHeader from "../shared/TopHeader";
import User from "../shared/User";
import bg from "../../assets/images/search_with_filter_nav_background.webp";
import HamBurger from "./HamBurger";

const Navbar = () => {
  return (
    <div>
      <TopHeader />
      <nav className="bg-[#105079]  h-[4.5rem]  xl:h-[5.4rem] flex items-center justify-between px-4 lg:px-12 w-full">
        <OnetechLogo />
        <User />
        <HamBurger />
      </nav>
      <div
        style={{
          backgroundImage: `url(${bg})`,
        }}
        className={`h-[25vh] lg:mt-[-5px] bg-left xl:bg-center bg-cover xl:h-[21.8rem] lg:bg-contain 2xl:bg-cover bg-no-repeat flex items-center pl-14`}
      >
        <h1 className="text-[1.6rem] leading-6 lg:text-[2.7rem] lg:leading-[2.8rem] font_bold text-[#105079] w-[70%] lg:w-[50%]">
          BUILDING THE <span className="text-[#005EEF]">SIMPLEST</span> UNILEVER
          EVER
        </h1>
      </div>
    </div>
  );
};

export default Navbar;
