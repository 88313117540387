import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home.page";
import FilterSearch from "./pages/Search.page";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/shared/PrivateRoute";
import Product from "./pages/Product.page";
import Footer from "./components/shared/footer/Footer";
import { ToastContainer } from "react-toastify";

const RouterComponent = () => {
  const location = useLocation();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="/search" element={<PrivateRoute />}>
          <Route path="/search" element={<FilterSearch />} />
        </Route>
        <Route path="/product/:productID" element={<PrivateRoute />}>
          <Route path="/product/:productID" element={<Product />} />
        </Route>
      </Routes>
      {location.pathname !== "/login" && <Footer />}
      <ToastContainer />
    </>
  );
};

export default RouterComponent;
