import { Suspense, lazy, useState } from "react";
// import ExcelDownloadButton from "../../utils/ExcelDownload";
import { useRef } from "react";
import { toast } from "react-toastify";
import { AiOutlineCopy } from "react-icons/ai";

const LazyExcelDownlodButton = lazy(() => import("../../utils/ExcelDownload"));

const HowToUse = () => {
  const [show, setShow] = useState(false);

  const textRef = useRef(null);

  const copyText = () => {
    const textToCopy = textRef.current.innerText;

    if (textToCopy) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          toast.success("Text copied to clipboard: " + textToCopy);
        })
        .catch((error) => {
          console.error("Copy failed:", error);
        });
    }
  };

  return (
    <div className="hidden lg:flex justify-end  gap-[10%] items-center ml-[3rem] font_bold text-[#fff] relative">
      {/* <div className="  text-[13px] text-center py-2 rounded-[4px] cursor-pointer">
        How to use
      </div> */}
      <div
        onMouseOver={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className="w-[50%] px-2 text-[13px] ml-10 whitespace-nowrap text-center py-2 rounded-[4px] cursor-pointer relative"
      >
        Upload My Products
      </div>
      {show && (
        <div
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
          className="absolute top-[2.2rem] left-[35%] bg-white shadow-md w-[77%] z-20 rounded-[0.7rem] px-4 py-4"
        >
          <div className="text-black text-[10px] font_medium text-center">
            Please download the template, fill and mail it to{" "}
            <span className="text-[#005EEF]" ref={textRef}>
              hap@uniliver.com
            </span>{" "}
            <AiOutlineCopy
              onClick={copyText}
              className="w-4 inline cursor-pointer"
            />{" "}
          </div>
          <Suspense fallback={<p>Loading...</p>}>
            {/* <ExcelDownloadButton /> */}
            <LazyExcelDownlodButton />
          </Suspense>
        </div>
      )}
    </div>
  );
};

export default HowToUse;
