import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import filterIcon from "../../assets/icons/filter.svg";
// import cross from "../../assets/icons/cross.svg";
import { useContext } from "react";
import ProductContext from "../../context/ProductContext";
import { useState } from "react";
import { useEffect } from "react";
import { clusterData } from "../../data/cluster.data";
import { filterData as filterType } from "../../data/filter.data";
import { useLocation } from "react-router-dom";
import { useRef } from "react";

const Filter = () => {
  const [filterData, setFilterData] = useState(
    JSON.parse(sessionStorage.getItem("query")) || {
      selectedCluster: "Cluster",
      selectedFunc: "Functions",
      selectedProcess: "Process",
      selectedSubProcess: "Sub-Process",
    }
  );

  const [processData, setProcessData] = useState([]);
  const [subProcessData, setSubProcessData] = useState([]);

  const { selectedFunc, selectedCluster, selectedProcess, selectedSubProcess } =
    filterData;

  const [showFilters, setShowFilters] = useState(false);
  const ref = useRef(true);
  const ref2 = useRef(true);
  const ref3 = useRef(true);
  const ref4 = useRef(true);
  const ref5 = useRef(true);

  const {
    getFilterProducts,
    productState: { products },
  } = useContext(ProductContext);

  const location = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }
    if (ref4.current) {
      ref4.current = false;
      return;
    }
    if (ref5.current) {
      ref5.current = false;
      return;
    }
    if (location.pathname === "/search") {
      const query = {
        cluster: selectedCluster,
        function: selectedFunc,
        area: selectedProcess,
        subarea: selectedSubProcess,
        limit: 12,
      };
      getFilterProducts(query);
    }

    //eslint-disable-next-line
  }, [selectedCluster, selectedFunc, selectedProcess, selectedSubProcess]);

  useEffect(() => {
    if (ref2.current) {
      ref2.current = false;
      return;
    }
    if (
      selectedFunc === "Supply Chain" &&
      selectedProcess !== "Process" &&
      processData?.length !== 0
    ) {
      const data = filterType["Supply Chain"]["Sub area"][selectedProcess];
      if (!data) return;
      setSubProcessData(data);
    }

    //eslint-disable-next-line
  }, [selectedProcess, processData]);

  useEffect(() => {
    if (ref3.current) {
      ref3.current = false;
      return;
    }
    if (selectedFunc && selectedFunc !== "Functions") {
      const functionDetails = filterType[selectedFunc];
      setProcessData(functionDetails["Area"]);
      if (selectedFunc !== "Supply Chain") {
        setSubProcessData(functionDetails["Sub area"]);
      } else {
        setSubProcessData([]);
      }
    } else {
      setProcessData([]);
      setSubProcessData([]);
    }

    setFilterData((prev) => ({
      ...prev,
      selectedProcess: "",
      selectedSubProcess: "",
    }));
  }, [selectedFunc]);

  const onSelectChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className={`flex flex-col gap-4 lg:gap-0 lg:flex-row  lg:justify-between lg:items-center uppercase mt-4 lg:mt-10 mb-16 px-4 lg:px-[4rem] w-full`}
      >
        <>
          {products?.length > 0 ? (
            <FilterBy />
          ) : (
            <h1 className="capitalize font_medium text-[14px] text-[#1F36C7]">
              There are no LIVE products for this combination in chosen cluster.
              Re-Unmaze using filter option with "Global" cluster.
            </h1>
          )}
          <FilterAction setShowFilters={setShowFilters} />
        </>
      </div>
      {showFilters && (
        <div className="flex px-4 lg:px-[4rem] mt-[-3rem] lg:mt-[-2rem] w-full ">
          <div className="bg-[rgba(217,217,217,0.2)] rounded-lg lg:rounded-none flex flex-col lg:flex-row justify-between gap-4 lg:gap-0 px-4 py-3">
            <div className="flex flex-col gap-[5px] w-full lg:w-[20%]">
              <label className="font_medium text-[#1F36C7] text-[0.85rem] ml-3">
                Cluster
              </label>
              <select
                onChange={onSelectChange}
                value={selectedCluster}
                name="selectedCluster"
                className="font_normal text-[13px] py-2 px-3 border-none outline-none"
              >
                <option value={""}>Select</option>
                {clusterData.map((clusterOption) => (
                  <option key={uuidv4()}>{clusterOption}</option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-[5px] w-full lg:w-[20%]">
              <label className="font_medium text-[#1F36C7] text-[0.85rem] ml-3">
                Function
              </label>
              <select
                onChange={onSelectChange}
                value={selectedFunc}
                name="selectedFunc"
                className="font_normal text-[13px] py-2 px-3 border-none outline-none"
              >
                <option value={""}>Select</option>
                {Object.keys(filterType).map((functions) => (
                  <option key={uuidv4()} value={functions}>
                    {functions}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-[5px] w-full lg:w-[20%]">
              <label className="font_medium text-[#1F36C7] text-[0.85rem] ml-3">
                Process
              </label>
              <select
                onChange={onSelectChange}
                value={selectedProcess}
                name="selectedProcess"
                className="font_normal text-[13px] py-2 px-3 border-none outline-none"
              >
                <option value={""}>Select</option>
                {processData?.map((process) => (
                  <option key={uuidv4()} value={process}>
                    {process}
                  </option>
                ))}
              </select>
            </div>

            <div className="flex flex-col gap-[5px] w-full lg:w-[20%]">
              <label className="font_medium text-[#1F36C7] text-[0.85rem] ml-3">
                Sub Process
              </label>
              <select
                onChange={onSelectChange}
                value={selectedSubProcess}
                name="selectedSubProcess"
                className="font_normal text-[13px] py-2 px-3 border-none outline-none"
              >
                <option value={""}>Select</option>
                {subProcessData.map((subProcess) => (
                  <option key={uuidv4()}>{subProcess}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Filter;

const FilterBy = () => {
  const [filterByState, setFilterByState] = useState([]);
  const {
    productState: { products },
  } = useContext(ProductContext);

  const ref = useRef(true);
  const ref2 = useRef(true);

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      return;
    }

    const query = JSON.parse(sessionStorage.getItem("query"));
    if (!query) return;
    let queryArr = [];
    for (let item in query) {
      queryArr.push({ [item]: query[item] });
    }
    setFilterByState(queryArr);
  }, [products]);

  useEffect(() => {
    if (ref2.current) {
      ref2.current = false;
      return;
    }

    const obj = {};
    filterByState.forEach((filter) => {
      let key = Object.keys(filter)[0];
      let value = Object.values(filter)[0];
      obj[key] = value;
    });

    sessionStorage.setItem("query", JSON.stringify(obj));
    //setSessionQuery(obj);
  }, [filterByState]);

  const removeFilter = (filter) => {
    setFilterByState(
      filterByState.filter(
        (item) => Object.keys(item)[0] !== Object.keys(filter)[0]
      )
    );
  };

  return (
    <div className="flex flex-col lg:flex-row gap-4 lg:justify-start lg:items-center font_medium w-full lg:w-[70%] ">
      <h1 className="text-[#005EEF] w-full lg:w-[24%]">FILTERED BY</h1>
      <div className="grid grid-cols-3 gap-4 lg:gap-8">
        {filterByState.map((item) => (
          <FilterByItem
            key={uuidv4()}
            filterProp={item}
            removeFilter={removeFilter}
          />
        ))}
      </div>
    </div>
  );
};

FilterBy.propTypes = {
  setFilterData: PropTypes.func,
};

const FilterByItem = ({ filterProp }) => {
  if (
    filterProp.limit !== Object.values(filterProp)[0] &&
    filterProp.skip !== Object.values(filterProp)[0] &&
    Object.values(filterProp)[0] !== ""
  )
    return (
      <span className="bg-[#EAEAEA] flex items-center justify-center text-[#105079] mx-0 lg:mx-2 text-center text-[0.7rem]  lg:text-[0.8rem] py-1 lg:py-2 px-3 rounded-[3rem] relative">
        {Object.values(filterProp)[0]}
        {/* <img
          src={cross}
          alt="cross"
          className="absolute -right-2 -top-3 w-6 aspect-square cursor-pointer"
          onClick={() => removeFilter(filterProp)}
        /> */}
      </span>
    );
};

FilterByItem.propTypes = {
  filterProp: PropTypes.object,
  removeFilter: PropTypes.func,
};

const FilterAction = ({ setShowFilters }) => {
  return (
    <div className="flex justify-start lg:justify-end gap-3 font_medium text-[0.85rem] w-[45%]">
      {/* <button className="bg-[#FFC000] text-white w-[6.5rem] rounded-[8px] py-2">
        COMPARE
      </button> */}
      <div
        className="flex justify-center py-2 items-center gap-1 bg-[#105079] text-[#9DED5C] w-[6.5rem] rounded-[2rem] cursor-pointer"
        onClick={() => setShowFilters((prev) => !prev)}
      >
        <img
          src={filterIcon}
          alt="filter_icon"
          className="w-[0.9rem] aspect-square ml-6 mt-1"
        />
        <span>FILTER</span>
      </div>
      {/* <div className="bg-[#105079] flex w-[35%] rounded-[2rem] p-[4px]">
        <div className="bg-[#005EEF] rounded-[2rem] flex justify-center items-center text-[#fff]">
          INSIDE
        </div>
        <div className="text-[#9DED5C] flex items-center justify-center">
          GARTNER
        </div>
      </div> */}
    </div>
  );
};

FilterAction.propTypes = {
  setShowFilters: PropTypes.func,
};
