import { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import axios from "axios";
import AuthContext from "./AuthContext";
import extractAxiosMessage from "../utils/extractAxiosMessage";
const baseurl = process.env.REACT_APP_BASE_URL;

const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [productState, setProductState] = useState({
    products: [],
    totalDataCount: null,
    product: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    type: "",
  });

  const [filterData, setFilterData] = useState([]);

  const [isPlaying, setIsPlaying] = useState(true);

  const {
    userState: { user },
  } = useContext(AuthContext);

  const resetProductState = () => {
    setProductState({
      ...productState,
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
      type: "",
    });
  };

  const setToLoading = () => {
    setProductState({ ...productState, isLoading: true });
  };

  const getPopularProducts = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };

    setToLoading();
    try {
      const res = await axios.get(`${baseurl}/products/popular`, config);
      setProductState({
        ...productState,
        products: res.data.products,
        isSuccess: true,
        isLoading: false,
        type: "products/get/popular",
      });
    } catch (error) {
      const message = extractAxiosMessage(error);
      setProductState({
        ...productState,
        isLoading: false,
        isError: true,
        type: "products/get/popular",
        message,
      });
    }
  };

  const getProducts = async (query) => {
    query = query ? query : JSON.parse(sessionStorage.getItem("query"));

    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      params: query,
    };

    setToLoading();
    try {
      const res = await axios.get(`${baseurl}/products`, config);
      setProductState({
        ...productState,
        products: res.data.products,
        totalDataCount: res.data?.totalDataCount,
        isSuccess: true,
        isLoading: false,
        type: "products/get",
      });
    } catch (error) {
      const message = extractAxiosMessage(error);
      setProductState({
        ...productState,
        isLoading: false,
        isError: true,
        type: "products/get",
        message,
      });
    }

    query && sessionStorage.setItem("query", JSON.stringify(query));
  };

  const getFilterProducts = async (query) => {
    query = query ? query : JSON.parse(sessionStorage.getItem("query"));

    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      params: query,
    };

    setToLoading();

    try {
      const res = await axios.get(`${baseurl}/products/filter`, config);
      setProductState({
        ...productState,
        products: res.data.products,
        totalDataCount: res.data?.totalDataCount,
        isSuccess: true,
        isLoading: false,
        type: "products/filter",
      });
    } catch (error) {
      const message = extractAxiosMessage(error);
      setProductState({
        ...productState,
        isError: true,
        isLoading: false,
        message,
        type: "products/filter",
      });
    }
    query && sessionStorage.setItem("query", JSON.stringify(query));
  };

  return (
    <ProductContext.Provider
      value={{
        filterData,
        setFilterData,
        getProducts,
        resetProductState,
        productState,
        getFilterProducts,
        getPopularProducts,
        isPlaying,
        setIsPlaying,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProductContext;
