import * as XLSX from "xlsx";

const ExcelDownloadButton = ({
  bg = "#105079",
  text = "#9DED5C",
  marginTop = "0.5rem",
  marginLeft = "1.5rem",
  font = "9px",
}) => {
  const handleDownloadClick = () => {
    const data = [
      {
        "Product name": "Anomaly Detection",
        "Product description":
          "An ML solution to detect anomalies or deviations in data with high accuracy.",
        "PRODUCT USP": "Lorem Ipsum",
        "Product ID": "ID of the product",
        ClusterLive: "North Asia",
        ClusterOnRoadmap: "Lorem Ipsum",
        CountryLive: "China",
        CountryRoadmap: "Lorem Ipsum",
        Technology: "RPA",
        "Business owner email": "sample@unilever.com",
        "Engagement lead": "sample@unilever.com",
        "Created by": "firstname.lastname@unilever.com",
        "Documents link": "Sharepoint link",
        Cost: "Cost of the product",
        "NPS Score": "NPS Score of the product",
        "Number of users": "Number of users using the product",
        "Video link": "Sharepoint link",
      },
    ];

    const headers = [
      "Product name",
      "Product description",
      "PRODUCT USP",
      "Product ID",
      "ClusterLive",
      "ClusterOnRoadmap",
      "Technology",
      "Business owner email",
      "Engagement lead",
      "Created by",
      "Documents link",
      "Cost",
      "NPS Score",
      "Number of users",
      "Video link",
    ];
    const sheetData = data.map((item) => [
      item["Product name"],
      item["Product description"],
      item["PRODUCT USP"],
      item["Product ID"],
      item.ClusterLive,
      item.ClusterOnRoadmap,
      item.Technology,
      item["Business owner email"],
      item["Engagement lead"],
      item["Created by"],
      item["Documents link"],
      item.Cost,
      item["NPS Score"],
      item["Number of users"],
      item["Video link"],
    ]);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...sheetData]);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    const fileName = "Product Template.xlsx";

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // For other browsers
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  return (
    <button
      style={{
        backgroundColor: bg,
        color: text,
        marginTop,
        marginLeft,
        fontSize: font,
      }}
      onClick={handleDownloadClick}
      className="w-[80%] rounded-md text-[10px] py-2 font_medium mx-auto"
    >
      Product Info Template
    </button>
  );
};

export default ExcelDownloadButton;
