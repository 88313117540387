const Heading = () => {
  return (
    <div className="uppercase font_bold text-[1.6rem] lg:text-[2.75rem]  text-center mt-6 leading-6 lg:leading-[2.75rem] 2xl:leading-[4rem] text-[#105079]">
      BUILDING THE <br />{" "}
      <span className="text-[#005EEF] relative after:contents-[''] after:bottom-[2px] after:w-[110%] after:left-[0] after:h-[1px] after:absolute after:bg-[#9ded5c]">
        SIMPLEST
      </span>{" "}
      <span className="relative after:contents-[''] after:bottom-[2px] after:w-[100%] after:left-[0] after:h-[1px] after:absolute after:bg-[#9ded5c]">
        UNILEVER YET
      </span>
    </div>
  );
};

export default Heading;
