import ProductList from "./ProductList";

const Product = () => {
  return (
    <div>
      <h1 className="mt-[-4rem] text-[#105079] text-[2rem] text-center font_bold">
        Popular Products
      </h1>
      <ProductList />
    </div>
  );
};

export default Product;
