import style from "./skeleton.module.css";

const Skeleton = () => {
  const spans = `${style.skeleton_box}  h-5  inline-block`;

  return (
    <div className="bg-gray-100 w-full p-8 grid grid-cols-1 xl:grid-cols-4 gap-5 mt-6">
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="p-2">
          <button className="flex flex-col relative w-full translate-3d-none-after bg-white overflow-hidden card translate-3d-none-after rounded border border-gray-300">
            <div
              className="relative group text-primary-500"
              style={{ paddingTop: "70%" }}
            >
              <div className="absolute top-0 left-0 h-full w-full">
                <span
                  className={`${style.skeleton_box} group-hover:scale-110 transition-transform transform-center block h-full`}
                ></span>
              </div>
            </div>
            <div className="flex flex-col flex-grow">
              <div className="pl-4 pr-4 pt-4 mb-4 text-left relative flex-grow">
                <h3 className="text-lg font-bold text-gray-darkest mr-10">
                  <span className={`${spans} w-1/6`}></span>
                  <span className={`${spans} w-1/2 ml-3`}></span>
                  <span className={`${spans} w-2/4`}></span>
                  <span className={`${spans} w-2/5 ml-3`}></span>
                </h3>
              </div>
            </div>
          </button>
        </div>
      ))}
    </div>
  );
};

export default Skeleton;
