import Search from "./Search";
import { useLocation } from "react-router-dom";
import Heading from "./Heading";
import Cluster from "./Cluster";
import TopHeader from "../shared/TopHeader";
import OnetechLogo from "../shared/OnetechLogo";
import User from "../shared/User";
import HowToUse from "./HowToUse";
import bg from "../../assets/images/home_background_min.webp";
import HamBurger from "./HamBurger";
import MobileScreenSearch from "./MobileScreenSearch";

const Navbar = () => {
  const location = useLocation();

  return (
    <div
      style={{ backgroundImage: `url(${bg})` }}
      className={`bg-no-repeat h-[50rem] md:h-[44rem] 2xl:h-[60rem] bg-cover bg-center translate-y-[-6rem] ${
        location.pathname === "/" && "pt-[6rem]"
      }`}
    >
      <TopHeader />
      <nav className="bg-[#105079] h-[4.5rem] lg:h-[5.4rem] flex items-center justify-between px-4 lg:p-10 xl:px-12 w-full">
        <OnetechLogo />
        <Search />
        <HowToUse />
        <User />
        <HamBurger />
      </nav>
      <MobileScreenSearch />
      <Heading />
      <Cluster />
    </div>
  );
};

export default Navbar;
