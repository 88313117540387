import { useState } from "react";
import Navbar from "../components/navbar/Navbar.product";
import ProductDetails from "../components/productPage/ProductDetails";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Sidebar from "../components/navbar/Sidebar";

const Product = () => {
  const [product, setProduct] = useState(null);
  const [changeProduct, setChangeProduct] = useState({
    prev: null,
    next: null,
  });
  const [currentIndex, setCurrentIndex] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setProduct(location.state.product);
    assignToPrevAndNext();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const products = location.state?.products || [];
    let leftIndex = currentIndex - 1;
    let rightIndex = currentIndex + 1;
    if (leftIndex < 0) return;
    if (rightIndex > products.length) return;

    setChangeProduct({
      prev: products[leftIndex],
      next: products[rightIndex],
    });
    setProduct(products[currentIndex]);
    //eslint-disable-next-line
  }, [currentIndex]);

  const assignToPrevAndNext = () => {
    const products = location.state?.products || [];
    const product = location.state.product || null;

    products.forEach((item, idx) => {
      if (item?._id === product?._id) {
        setCurrentIndex(idx);
      }
    });
  };

  return (
    <div className="drawer drawer-end">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <Navbar product={product} />
        <ProductDetails
          product={product}
          changeProduct={changeProduct}
          setProduct={setProduct}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </div>
      <Sidebar />
    </div>
  );
};

export default Product;
