import { useState } from "react";
import searchIcon from "../../assets/icons/search.svg";
import { useContext } from "react";
import ProductContext from "../../context/ProductContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Search = () => {
  const [searchText, setSearchText] = useState("");
  const { getProducts } = useContext(ProductContext);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!searchText) return;

    if (searchText.length > 2) {
      const query = { limit: 12, search: searchText, cluster: "Global" };
      console.log("calling search query", query);
      await getProducts(query);
    } else {
      return toast.error("Too small word");
    }
    sessionStorage.setItem("search", searchText);
    navigate("/search");
  };

  return (
    <>
      <form
        className="gap-2 hidden lg:flex lg:w-[180%] xl:w-[140%]"
        onSubmit={onSubmit}
      >
        <div className="w-[90%] bg-white border-[1px] flex justify-between px-4 py-[0.6rem] rounded-[4px] text-[12px] font_medium">
          <input
            type="text"
            placeholder="Find the Product"
            className="w-[80%] border-none outline-none"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            maxLength={40}
          />
        </div>
        <button
          type="submit"
          className="w-[8%] bg-white flex justify-center items-center rounded-[4px] cursor-pointer"
        >
          <img
            src={searchIcon}
            alt="search"
            className="w-[0.9rem] h-[0.9rem]"
          />
        </button>
      </form>
    </>
  );
};

export default Search;
