export const clusterData = [
  "Global",
  "Europe",
  "North America",
  "NAMETRUB",
  "North Asia",
  "SEAA",
  "South Asia",
  "LATAM",
  "Africa",
];
