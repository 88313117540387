export const filterData = {
  Finance: {
    Area: ["Hyper Automation"],
    "Sub area": [
      "Others",
      "Supply Chain Finance",
      "Record to Report",
      "Bill To Cash",
      "Treasury",
      "Control Service Centre",
      "ETS Finance",
      "Source to Pay",
    ],
  },
  "IT Services": {
    Area: ["Hyper Automation"],
    "Sub area": ["Authorization", "Automation Factory", "AM"],
  },
  "Supply Chain": {
    Area: ["Make", "Plan", "Hyper Automation"],
    "Sub area": {
      Make: [
        "QualityOne",
        "Digital Factory Operating System",
        "Manufacturing Data Collection System",
        "SHENET",
        "MSEW",
      ],
      Plan: [
        "P&L forecasting",
        "Plan to Forecast",
        "Warehousing/Logistics",
        "Supply Chain planning",
      ],
      "Hyper Automation": [
        "Deliver",
        "Plan",
        "Make",
        "Order to Cash",
        "Automation Factory",
        "Master Data",
        "Source to Pay",
        "Customer Service",
        "Supply Chain",
        "eCommerce",
        "Others",
      ],
    },
  },
  "Customer Development": {
    Area: ["Hyper Automation"],
    "Sub area": [
      "Customer Development Operations",
      "Customer Development",
      "Customer Development Excellence",
      "Consumer Management",
      "Others ",
    ],
  },
  Marketing: {
    Area: ["Hyper Automation"],
    "Sub area": [
      "Others",
      "Artwork Excellence",
      "eCommerce",
      " Marketing",
      " CMI",
      "Customer Marketing",
    ],
  },
  "R&D": {
    Area: ["Hyper Automation"],
    "Sub area": ["PLM", "Digital R&D", "Others", "R&D", "Artwork Excellence"],
  },
  Legal: {
    Area: ["Hyper Automation"],
    "Sub area": ["Legal", "CMI"],
  },
  "HR Services": {
    Area: ["Hyper Automation"],
    "Sub area": ["Payroll & Rewards"],
  },
  "I&A": {
    Area: ["Hyper Automation"],
    "Sub area": ["Reporting"],
  },
};
