import PropTypes from "prop-types";

const Footer = () => {
  return (
    <div className="bg-[#005EEF] text-center  mx-auto mt-6 lg:mt-10 py-2 text-white">
      <span className="">Contact:</span>{" "}
      <a href="mailto:hap@unilever.com">hap@unilever.com</a>
    </div>
  );
};

const FooterItem = ({ data }) => {
  return (
    <div>
      <img
        src={data?.icon}
        alt={"icon"}
        loading="lazy"
        className="w-12 aspect-square"
      />
      <h1 className="font_bold mt-6 text-[1.3rem]">{data?.title}</h1>
      <p className="font_normal text-[13px] w-[65%] mt-4 mb-3">
        {data?.details}
      </p>
      <a href={`mailto:${data?.link}`} className="font_medium text-[13px]">
        {data?.link}
      </a>
    </div>
  );
};

FooterItem.propTypes = {
  data: PropTypes.object,
};

export default Footer;
