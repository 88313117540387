import FilterSearchResult from "../components/filterSearchResult/FilterSearchResult";
import Navbar from "../components/navbar/Navbar.search";
import SearchInput from "../components/search/Search.input";
import Sidebar from "../components/navbar/Sidebar";

const FilterSearch = () => {
  return (
    <div className="drawer drawer-end">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <Navbar />
        <SearchInput />
        <FilterSearchResult />
      </div>
      <Sidebar />
    </div>
  );
};

export default FilterSearch;
