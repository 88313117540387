// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productitem_card__VR6Zg {
    position: relative;
    perspective: 30rem;
}

.productitem_front__vc\\+Lg
{
    position: relative;
    transition: transform 0.75s;
    backface-visibility: hidden;
    transform-style: preserve-3d;
}
.productitem_back__ddjEB {
    position: absolute;
    transition: transform 0.75s;
    backface-visibility: hidden;
    cursor: default;
    border-radius: 5px;
    z-index: -20;
    height: 100%;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
}

.productitem_card__VR6Zg:hover .productitem_front__vc\\+Lg {
    transform: rotateY(180deg);
    backface-visibility: hidden;
    
}
.productitem_card__VR6Zg:hover .productitem_back__ddjEB {
    transform: rotateY(360deg);
    background-color: #EBF3FA;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.productitem_active__WYBqG {
    columns: #000;
}

.productitem_card__VR6Zg:has(.productitem_active__WYBqG) .productitem_front__vc\\+Lg {
    transform: rotateY(180deg);
    backface-visibility: hidden;
    
}
.productitem_card__VR6Zg:has(.productitem_active__WYBqG) .productitem_back__ddjEB {
    transform: rotateY(360deg);
    background-color: #EBF3FA;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


`, "",{"version":3,"sources":["webpack://./src/components/products/productitem.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;;IAEI,kBAAkB;IAClB,2BAA2B;IAC3B,2BAA2B;IAC3B,4BAA4B;AAChC;AACA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,2BAA2B;IAC3B,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,MAAM;IACN,OAAO;IACP,4BAA4B;AAChC;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;;AAE/B;AACA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,iDAAiD;AACrD;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;;AAE/B;AACA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,iDAAiD;AACrD","sourcesContent":[".card {\n    position: relative;\n    perspective: 30rem;\n}\n\n.front\n{\n    position: relative;\n    transition: transform 0.75s;\n    backface-visibility: hidden;\n    transform-style: preserve-3d;\n}\n.back {\n    position: absolute;\n    transition: transform 0.75s;\n    backface-visibility: hidden;\n    cursor: default;\n    border-radius: 5px;\n    z-index: -20;\n    height: 100%;\n    top: 0;\n    left: 0;\n    transform-style: preserve-3d;\n}\n\n.card:hover .front {\n    transform: rotateY(180deg);\n    backface-visibility: hidden;\n    \n}\n.card:hover .back {\n    transform: rotateY(360deg);\n    background-color: #EBF3FA;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n\n.active {\n    columns: #000;\n}\n\n.card:has(.active) .front {\n    transform: rotateY(180deg);\n    backface-visibility: hidden;\n    \n}\n.card:has(.active) .back {\n    transform: rotateY(360deg);\n    background-color: #EBF3FA;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `productitem_card__VR6Zg`,
	"front": `productitem_front__vc+Lg`,
	"back": `productitem_back__ddjEB`,
	"active": `productitem_active__WYBqG`
};
export default ___CSS_LOADER_EXPORT___;
