// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton_skeleton_box__LjFoT {
    position: relative;
    overflow: hidden;
    background-color: #e2e8f0;
}

.skeleton_skeleton_box__LjFoT::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: skeleton_shimmer__3HkGm 3s infinite;
    content: '';
}
@keyframes skeleton_shimmer__3HkGm {
    100% {
      transform: translateX(100%);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/shared/skeleton.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,SAAS;IACT,OAAO;IACP,4BAA4B;IAC5B;;;;;;KAMC;IACD,8CAA8B;IAC9B,WAAW;AACf;AACA;IACI;MACE,2BAA2B;IAC7B;EACF","sourcesContent":[".skeleton_box {\n    position: relative;\n    overflow: hidden;\n    background-color: #e2e8f0;\n}\n\n.skeleton_box::after {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    transform: translateX(-100%);\n    background-image: linear-gradient(\n      90deg,\n      rgba(255, 255, 255, 0) 0,\n      rgba(255, 255, 255, 0.2) 20%,\n      rgba(255, 255, 255, 0.5) 60%,\n      rgba(255, 255, 255, 0)\n    );\n    animation: shimmer 3s infinite;\n    content: '';\n}\n@keyframes shimmer {\n    100% {\n      transform: translateX(100%);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton_box": `skeleton_skeleton_box__LjFoT`,
	"shimmer": `skeleton_shimmer__3HkGm`
};
export default ___CSS_LOADER_EXPORT___;
