import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";

const Loader = ({ color, style }) => {
  return <BeatLoader color={color || "#9DED5C"} size={10} style={style} />;
};

Loader.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Loader;
