const statData = [
  {
    id: 1,
    title: "Supply Chain PLAN Products",
    value: "234",
  },
  {
    id: 2,
    title: "Supply Chain  MAKE Products",
    value: "30",
  },
  {
    id: 3,
    title: "HyperAutomation Products",
    value: "99",
  },
  {
    id: 4,
    title: "Users",
    value: "20,000+",
  },
];

export default statData;
